
import { Component, Vue } from "vue-property-decorator";
import LayoutDefault from "../layouts/LayoutDefault.vue";
import MainBanner from "../components/MainBanner.vue";
import TitleText from "../components/TitleText.vue";
import PathScroller from "../components/PathScroller.vue";
import CardShowcase from "@/components/CardShowcase.vue";
import PageBreak from "@/components/PageBreak.vue";
import IconList from "@/components/IconList.vue";

@Component<any>({
  metaInfo() {
      return {
        title: "About Leighton - Tech, Talent, Together",
        meta: [{
          vmid: 'description',
          name: 'description',
          content: `${ this.banner.text }`,
        }]
    }
  },
  components: {
    LayoutDefault,
    MainBanner,
    TitleText,
    CardShowcase,
    PathScroller,
    PageBreak,
    IconList
  }
})
export default class About extends Vue {

  private banner = {
    heading: "We bring people and technology together",
    emphasis: "fast.",
    text:
      "At Leighton we are on a mission to help organisations and their customers thrive in today’s fast paced world.",
    action: {
      text: "Learn More"
    },
    image: require("../assets/banner-images/about.png")
  };

  private titleText1 = {
    customClass: "my-md",
    subTitle: `
      For as long as we can remember (and we’ve been around for nearly 30 years) organisations have struggled to deliver digital change. That isn’t a criticism, it’s the reality, we get it, it is difficult.
      <br>
      <br>
      Technology continues to change, user expectations (and those of your colleagues) continue to rise, meanwhile you’ve got the day job to do and simply don’t have the time or often the right expertise to get the next project over the line.
      <br>
      <br>
      <strong>That is where we come in.</strong>
    `
  };

  private titleText1a = {
    customClass: "background--grey pt-md",
    heading: "A New Type of Partner",
    subTitle: `
      We understand the problem you face. Finding, retaining and developing your team of technologists is not getting any easier, in fact, it is getting harder. Outsourcing isn’t quick enough, and those large consultancies and agencies aren’t cost effective. 
      <br>
      <br>
      <strong>Plus, sometimes you only need a little bit of help.</strong>
      <br>
      <br>
      That is where we come in, our completely flexible model enables you to choose a bespoke blend of our tech and talent so that together we work in partnership with you to co-create exceptional and innovative software solutions that solve big problems.  Depending on whether your project only needs a couple of weeks’ worth of support, or will run for the next 12 months or whether you need a specialist skill or a whole team of engineers – we are the flexible partner that you need.
    `,
    decorator: [
      {
        image: require("../assets/page-decorators/orange-pill.png"),
        type: "orange-pill d-none d-md-block",
        right: "1250px",
        top: "-100px"
      },
      {
        image: require("../assets/page-decorators/orange-pill.png"),
        type: "orange-pill d-md-none",
        right: "400px",
        top: "-100px",
        height: "260px"
      }
    ]
  };

  private titleText2 = {
    customClass: "background--grey pt-md",
    heading: "Our Approach",
    subTitle: `
      Our process is The Path. We created it, so we can deliver against your goals. Your agenda is our agenda.  It’s where our tech and talent offering come together. The thinkers and the doers. With you; together we customise the right blend of tech and talent. No two customers are the same. You get what you need. You get it when you need it. You can turn it up. You can turn it down. Flexibility is the key word here.
    `
  };

  private titleText2a = {
    heading: "We call it The Path",
    subTitle: `
      The Path to success is well proven, every customer has a unique journey but with Leighton you can be confident that The Path to success is well proven.
      <br>
      <br>
      <strong>The Path delivers from the very start…</strong>
    `,
    decorator: [
      {
        image: require("../assets/page-decorators/bubble4.png"),
        type: "bubble-four",
        top: "-100px",
        right: "-260px"
      }
    ]
  };

  private titleText2b = {
    customClass: "mt-md",
    heading: "The Benefits of our Approach"
  };

  private pathScroller1 = {
    v2: true,
    customClass: "background--grey py-md",
    item: [
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath1.png"),
        alt: "Quality Seal icon",
        text: `
          <span class="emphasise--dark">Agreeing the Strategy</span>
          <br>
          Understanding your vision and goals and aligning on what success looks like
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath2.png"),
        alt: "Magnigying glass icon",
        text: `
          <span class="emphasise--dark">Designing the Solution</span>
          <br>
          3 steps to success. Fully exploring and understanding you and your needs, defining clear scope and solution outcomes & designing exceptional and innovative solutions
        `
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath3.png"),
        alt: "Checklist icon",
        text: `
          <span class="emphasise--dark">Delivering the solution</span>
          <br>
          With pace and experitse, using the best technology and proven DevOps methodology
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath4.png"),
        alt: "Double arrows icon",
        text: `
          <span class="emphasise--dark">Optimizing success</span>
          <br>
          Ongoing support and expertise to continually optimize business results
        `
      }
    ],
    itemMobile: [
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath1-mobile.png"),
        alt: "Quality Seal icon",
        text: `
          <span class="emphasise--dark">Agreeing the Strategy</span>
          <br>
          Understanding your vision and goals and aligning on what success looks like
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath2-mobile.png"),
        alt: "Magnigying glass icon",
        text: `
          <span class="emphasise--dark">Designing the Solution</span>
          <br>
          3 steps to success. Fully exploring and understanding you and your needs, defining clear scope and solution outcomes & designing exceptional and innovative solutions
        `
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath3-mobile.png"),
        alt: "Checklist icon",
        text: `
          <span class="emphasise--dark">Delivering the solution</span>
          <br>
          With pace and experitse, using the best technology and proven DevOps methodology
        `,
        textTop: true
      },
      {
        icon: require("@/assets/pathscroller-images/about-us/newpath4-mobile.png"),
        alt: "Double arrows icon",
        text: `
          <span class="emphasise--dark">Optimizing success</span>
          <br>
          Ongoing support and expertise to continually optimize business results
        `
      }
    ]
  };

  private pathScroller = {
    customClass: "mt-md",
    item: [
      {
        icon: require("../assets/pathscroller-images/path-1.png"),
        alt: "Quality Seal icon",
        text: "Quality guaranteed"
      },
      {
        icon: require("../assets/pathscroller-images/path-2.png"),
        alt: "Magnigying glass icon",
        text: "Full commercial and project transparency"
      },
      {
        icon: require("../assets/pathscroller-images/path-3.png"),
        alt: "Checklist icon",
        text: "Fully accessible and compliant solutions"
      },
      {
        icon: require("../assets/pathscroller-images/path-4.png"),
        alt: "Double arrows icon",
        text: "Minimal business change - we work with your business"
      },
      {
        icon: require("../assets/pathscroller-images/path-5.png"),
        alt: "Flowchart Icon",
        text: "An agile and flexible approach to project delivery"
      },
      {
        icon: require("../assets/pathscroller-images/path-6.png"),
        alt: "Delivery icon",
        text: "Pace!"
      },
      {
        icon: require("../assets/pathscroller-images/path-7.png"),
        alt: "Handshake icon",
        text: "Co-creation at every stage"
      }
    ]
  };

  private titleText3 = {
    customClass: "mt-md",
    title: "We practice what we preach",
    subTitle: `
    For nearly 30 years we’ve been trusted by some of the world’s biggest brands to build high performing teams and successful software applications.
    <br>
    <br>
    We’ve done it again and again. We use The Path for our own business ideas too and have helped create innovative companies that now operate on a global scale:-
    `,
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble3-large.png"),
        type: "bubble-four d-none d-md-block",
        top: "0",
        right: "-460px"
      },
      {
        image: require("@/assets/page-decorators/purple-pill.png"),
        type: "purple-pill d-none d-md-block",
        top: "600px",
        left: "-100px"
      },
      {
        image: require("@/assets/page-decorators/purple-pill.png"),
        type: "purple-pill d-md-none",
        top: "950px",
        left: "-50px",
        height: "260px"
      }
    ]
  };

  private partnerCards = {
    customClass: "partner-cards mt-md",
    showcaseTypeMobile: "single-scroller",
    showcaseTypeDesktop: "static",
    icons: true,
    squareTiles: true,
    values: [
      {
        icon: require("../assets/partners-images/human.png"),
        text:
          "human is a customisable employee onboarding platform that supports the HR function by saving time, improving governance and creating a faster more engaging induction process."
      },
      {
        icon: require("../assets/partners-images/salecycle.png"),
        text:
          "SaleCycle’s data driven features help you to increase conversions, recover sales, drive loyalty and understand your customers."
      },
      {
        icon: require("../assets/partners-images/communicator.png"),
        text:
          "Communicator is a trusted partner in sophisticated email and SMS marketing, with a focus on helping ambitious brands achieve breakthrough results."
      },
      {
        icon: require("../assets/partners-images/footycom.png"),
        text:
          "Footy.com is the home of football price comparison enabling you to compare prices from 100+ retailers."
      },
      {
        icon: require("../assets/partners-images/workcast.png"),
        text:
          "Workcast are the leading Webinar, Webcast and Virtual Event provider who have helped to grow business with online events since 2008."
      },
      {
        icon: require("../assets/partners-images/4projects.png"),
        text:
          "4Projects became the UK’s largest cloud based project management extranet for the construction sector prior to its acquisition by Viewpoint."
      },
      {
        icon: require("../assets/partners-images/domainnames.png"),
        text:
          "DomainNames.com was Europe’s biggest domain name registrar with the world’s first automated domain name registration process prior to its acquisition by Verisign."
      }
    ]
  };

  private titleText4 = {
    customClass: "mt-md",
    title: "Our Values",
    subTitle:
      "Our Values We’re go-getters and big thinkers and we work hard to make your vision a reality. Our values underpin everything we do and how we act. Without them we can’t achieve your dreams.",
    decorator: [
      {
        image: require("@/assets/page-decorators/bubble3-medium.png"),
        type: "bubble-three",
        top: "-400px",
        right: "1200px"
      },
      {
        image: require("@/assets/page-decorators/bubble3-small.png"),
        type: "bubble-three",
        top: "-75px",
        right: "1130px"
      },
      {
        image: require("@/assets/page-decorators/light-green-pill.png"),
        type: "light-green-pill d-none d-md-block",
        top: "500px",
        right: "1300px"
      },
      {
        image: require("@/assets/page-decorators/light-green-pill.png"),
        type: "light-green-pill d-md-none",
        top: "750px",
        right: "500px",
        height: "260px"
      },
      {
        image: require("@/assets/page-decorators/bubble3-small.png"),
        type: "bubble-four d-md-none",
        top: "-100px",
        right: "-20px"
      }
    ]
  };

  private cards = {
    customClass: "my-md",
    showcaseTypeMobile: "single-scroller",
    showcaseTypeDesktop: "single-scroller",
    icons: true,
    squareTiles: false,
    values: [
      {
        icon: require("@/assets/values-icons/value1.png"),
        title: "Our curiosity inspires the amazing",
        text:
          "Our curiosity is boundless, we ask lots of questions and are open minded and we love exploring new ideas. We aren’t afraid of failure and love to push at the boundaries of the possible as we seek positive change."
      },
      {
        icon: require("@/assets/values-icons/value2.png"),
        title: "Passionate about what we do, proud of what we achieve",
        text:
          "Ok, so the first thing you need to know is we love what we do and we never settle for second best. We always take ownership of our actions and reflect on the work that we have done so we are even better next time."
      },
      {
        icon: require("@/assets/values-icons/value3.png"),
        title: "We act with honesty and truth, always!",
        text:
          "We do what we say we are going to do, when we say we will do it – always! We use good judgement and do the “right thing”."
      },
      {
        icon: require("@/assets/values-icons/value4.png"),
        title:
          "We nurture our amazing people to be the best version of themselves",
        text:
          "We never stop learning and constantly want to improve. At Leighton, everyone is a teacher and a student. That is why our customers keep coming back for more."
      },
      {
        icon: require("@/assets/values-icons/value5.png"),
        title:
          "We collaborate to bring our purpose to life in a caring and supportive way ",
        text:
          "So, we admit it, we are different to everyone else. We really care about our customers and each other. We want to make a difference, we will deliver, we value you and will support you in the pursuit of your goals."
      }
    ]
  };
}
